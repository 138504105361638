import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SubTitle from "../components/SubTitle"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const WebHybridAnalysisPageTemplate = ({
  seo,
  hero,
  contactus,
  scheduleSection,
  leveragesection,
  haowsection,
  wcdastsection,
  tpfpsection,
  fixedimagesection
}) => (
    <div className="custompages">
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">

          <div className="columns">
            <div className="column">
              <SubTitle textAlign="center" title={contactus.title} />
            </div>
          </div>
          <div className="columns">
            <div className="column  has-text-centered">
              <a href={contactus.buttonlink} >
                <button class="btn-light">{contactus.buttontxt}</button>
              </a>
            </div>
          </div>





        </div>
      </section>

      <section className="section" style={{ background: `${scheduleSection.sectionbg}` }}>
        <div className="container scedulesection">
          {
            scheduleSection.description.list.map((description, i) => {
              return (
                <div key={i}>
                  <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{description.title}</p>
                </div>
              )
            })
          }


          <div className="columns" >
            <div className="column is-6 " style={{ marginTop: "1.5rem" }}>
              <a href={scheduleSection.buttonlink} >
                <button class="btn-light">{scheduleSection.buttontxt}</button>
              </a>
            </div>
            <div className="column is-2 mobile-center has-text-centered">
            </div>
            <div className="column mobile-center has-text-centered hero-image">
              <div style={{ maxWidth: 250, height: 90 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Yellow-Alien-5.png' }} />

              </div>
            </div>
          </div>

        </div>
      </section>


      <section className="section" style={{ background: `${leveragesection.sectionbg}` }}>
        <div className="container assesmentsection">
          <div style={{
            marginTop: "1rem"
          }}>
            <div className="columns" >
              <div className="column is-5">
                < SubTitle title={leveragesection.title} color={leveragesection.textcolor} />
              </div>
            </div>
            {
              leveragesection.description.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }
            <ul style={{ listStyleType: 'disc', paddingLeft: '3rem', color: '#fff' }}>
              {
                leveragesection.orderlist.list.map((order, i) => {
                  return (
                    <li style={{ marginTop: '1rem' }}>
                      {order.title}
                    </li>
                  )
                })
              }
            </ul>
            {
              leveragesection.description2.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }

            <div className="columns" style={{ margin: "1.1rem 0 0" }}>
              <div className="column  ">
                <a href={leveragesection.buttonlink} >
                  <button class="">{leveragesection.buttontxt}</button>
                </a>
              </div>
            </div>





          </div>
        </div>
      </section>




      <section className="section" style={{ background: `${haowsection.sectionbg}` }}>
        <div className="container scedulesection">
          <div style={{ margin: "1rem 0 0" }}>
            <SubTitle title={haowsection.title} color="#ffffff" />
            {
              haowsection.description.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#53c5d1" }}>{description.title}</p>
                  </div>
                )
              })
            }


            <div className="columns" >
              <div className="column is-6 " style={{ marginTop: "1.5rem" }}>
                <a href={haowsection.buttonlink} >
                  <button class="btn-light">{haowsection.buttontxt}</button>
                </a>
              </div>
              <div className="column is-2 mobile-center has-text-centered">
              </div>
              <div className="column mobile-center has-text-centered hero-image">
                <div style={{ maxWidth: 250, height: 90 }}>
                  <PreviewCompatibleImage imageInfo={{ image: '/img/mobile_testing_services.png' }} />

                </div>
              </div>
            </div>



          </div>
        </div>
      </section>



      <Brands />
      <WhyChooseUs
        title="Our security engineers are pros at conducting hybrid analysis to test the security of your web
        applications."
        list={[{
          title: 'We are security experts who train others.',
          para: 'Our web application security specialists regularly instruct for large corporations and global training institutions. We teach developers and organizations on how to properly perform web application hybrid analysis.'
        }, {
          title: 'We are all developers and we understand code.',
          para: 'We aren’t only experts in security, we also know how web applications are (and SHOULD be) built. We can leverage this knowledge to provide the most thorough security reviews for your web apps.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Know If Your Web Applications Are Secure?',
        subtitle: '',
        para: [
          'Let our security engineers conduct a web application hybrid analysis to identify vulnerabilities in your apps and provide you with an integrated report on how to fix them to mitigate threats.'

        ],
        linkTitle: 'Talk to Our Experts Today'
      }} />


    </div>
  )

WebHybridAnalysisPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  scheduleSection: PropTypes.object,
  leveragesection: PropTypes.object,
  haowsection: PropTypes.object,
  wcdastsection: PropTypes.object,
  tpfpsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const WebHybridAnalysisPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <WebHybridAnalysisPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        scheduleSection={frontmatter.scheduleSection}
        leveragesection={frontmatter.leveragesection}
        haowsection={frontmatter.haowsection}
        wcdastsection={frontmatter.wcdastsection}
        tpfpsection={frontmatter.tpfpsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

WebHybridAnalysisPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WebHybridAnalysisPage

export const pageQuery = graphql`
  query WebHybridAnalysisPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "web-hybrid-analysis" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        scheduleSection{
          sectionbg
          textcolor
          description{
            list{
              title
            }
          }
          buttontxt
          buttonlink
        }
        leveragesection{
          sectionbg
          textcolor
          title
          description{
            list{
              title
            }
          }
          orderlist{
            list{
              title
            }
          }
          description2{
           list{
              title
            }
          }
          buttontxt
          buttonlink

        }
        haowsection {
          sectionbg
          textcolor
          title
          description{
          list {
            title
            }
          }
          buttontxt
          buttonlink
        }
        wcdastsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tpfpsection {
          title
          secondtitle
          subheader
          sidedescriptions
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
